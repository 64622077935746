export const app_title = "DBManager Portal";

export const endpoint = process.env.REACT_APP_ENDPOINT;

// validations config start
export const emailValidation = {
  regex: /^\w+([._%+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  message: "Please enter a valid email",
};
export const passwordValidation = {
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,32}$/,
  // regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/,
  message:
    "Password must be of 8 to 32 characters containing atleast one lowercase letter, one uppercase letter, one numeric digit and one special character (@ $ ! % * ? & # .). Whitespaces are not allowed.",
};
export const fullNameValidation = {
  regex: /^[a-zA-Z]([-' ]?[a-zA-Z]+)+$/,
  message: "Please mention first name and last name",
};
export const nameValidation = {
  regex: /^[a-zA-Z\s]{3,255}$/,
  message:
    "Name must be of 3 to 255 characters containing only alphabets and spaces",
};
export const descriptionValidation = {
  regex: /^[a-zA-Z0-9\s,'-]{3,255}$/,
  message:
    "Description must be of 3 to 255 characters containing only alphabets, numbers, spaces and special characters like ,-'",
};
// export const uuidValidation = {
//   regex:
//     /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi,
//   message: "Please enter a valid UUID",
// };
// export const priceValidation = {
//   regex: /^[0-9]+(\.[0-9]{1,2})?$/gm,
//   message: "Please enter a valid Price",
// };
export const integerValidation = {
  regex: /^[0-9]*$/gm,
  message: "Please enter an integer number",
};

export const floatValidation = {
  regex: /^-?[0-9]\d*(\.\d+)?$/gm,
  message: "Please enter a valid number",
};

export const dateValidation = {
  regex: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/gm,
  message: "Please enter an valid date(YYYY-MM-DD)",
};
// validations config end

export const pageTitleValidation = {
  regex: /^(?!\s)[a-zA-Z0-9\s-]{0,25}(?<!\s)$/,
  message:
    "Page title must not exceed 25 characters and should only contain alphabets, numbers, spaces, and special characters like '-', and it cannot start or end with a space" ,
};

//currency codes
// export const currencyISOCode = [
//   "CAD",
//   "USD",
//   "AED",
//   "AFN",
//   "ALL",
//   "AMD",
//   "ANG",
//   "AOA",
//   "ARS",
//   "AUD",
//   "AWG",
//   "AZN",
//   "BAM",
//   "BBD",
//   "BDT",
//   "BGN",
//   "BIF",
//   "BMD",
//   "BND",
//   "BOB",
//   "BRL",
//   "BSD",
//   "BWP",
//   "BYN",
//   "BZD",
//   "CDF",
//   "CHF",
//   "CLP",
//   "CNY",
//   "COP",
//   "CRC",
//   "CVE",
//   "CZK",
//   "DJF",
//   "DKK",
//   "DOP",
//   "DZD",
//   "EGP",
//   "ETB",
//   "EUR",
//   "FJD",
//   "FKP",
//   "GBP",
//   "GEL",
//   "GIP",
//   "GMD",
//   "GNF",
//   "GTQ",
//   "GYD",
//   "HKD",
//   "HNL",
//   "HRK",
//   "HTG",
//   "HUF",
//   "IDR",
//   "ILS",
//   "INR",
//   "ISK",
//   "JMD",
//   "JPY",
//   "KES",
//   "KGS",
//   "KHR",
//   "KMF",
//   "KRW",
//   "KYD",
//   "KZT",
//   "LAK",
//   "LBP",
//   "LKR",
//   "LRD",
//   "LSL",
//   "MAD",
//   "MDL",
//   "MGA",
//   "MKD",
//   "MMK",
//   "MNT",
//   "MOP",
//   "MRO",
//   "MUR",
//   "MVR",
//   "MWK",
//   "MXN",
//   "MYR",
//   "MZN",
//   "NAD",
//   "NGN",
//   "NIO",
//   "NOK",
//   "NPR",
//   "NZD",
//   "PAB",
//   "PEN",
//   "PGK",
//   "PHP",
//   "PKR",
//   "PLN",
//   "PYG",
//   "QAR",
//   "RON",
//   "RSD",
//   "RUB",
//   "RWF",
//   "SAR",
//   "SBD",
//   "SCR",
//   "SEK",
//   "SGD",
//   "SHP",
//   "SLE",
//   "SLL",
//   "SOS",
//   "SRD",
//   "STD",
//   "SZL",
//   "THB",
//   "TJS",
//   "TOP",
//   "TRY",
//   "TTD",
//   "TWD",
//   "TZS",
//   "UAH",
//   "UGX",
//   "UYU",
//   "UZS",
//   "VND",
//   "VUV",
//   "WST",
//   "XAF",
//   "XCD",
//   "XOF",
//   "XPF",
//   "YER",
//   "ZAR",
//   "ZMW",
// ];
